import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./Tagline.css";

function Tagline() {
  return (
    <div className="outer" style={{ marginTop: "-1.3%" }}>
      <div className="sc_title_accent">
        <div className="title_wrap_in sc_align_right">
          <div className="title_wrap">
            <h6>ERAM CAPITAL PARTNER</h6>
            <h2 className="tagline-heading">
              Special
              <br /> Situations <br />
              Investment <br />
              Company
            </h2>
          </div>
          <div className="des_wrap">
            <p>
              We believe in creating lasting value for our investors through
              prudent investment of their capital. We invest across various
              sectors from private equity, real estate to energy and yield
              superior benefits for our clients.
            </p>
            <div className="more">
              <Link to="/firm">
                <Button variant="light" size="lg" className="tagline__btn">
                  More About Us <i class="fas fa-arrow-right"></i>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tagline;
