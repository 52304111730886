import React from "react";
import { useHistory } from "react-router-dom";
import realEstate from '../../assets/images/estate.jpg';
import infra from '../../assets/images/infra-bg.png';
import agri from '../../assets/images/agriculture.jpg';
import energy from '../../assets/images/energy.jpg';
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Firm.css";
import NavbaR from "../Navbar/NavbaR";
import {BsDot} from 'react-icons/bs';
import {BsArrowRight} from 'react-icons/bs';

function Firm() {
  const history = useHistory();

  return (
    <div className="strategy-main">
    <div className="firm-top-div">
      <div><NavbaR/></div>
      <h1 className="strategy-label">Our Firm</h1>
    </div>

    <div className="strategy-evaluation-main">
      <h3 className="strategy-evaluation-title">The Importance of Defining Your Investment Strategy</h3>
      <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
      <p className="evaluation-details"  id='evaluation-details-id'>Eram Capital is a special situations investment company focusing on bringing unique investment opportunities to global institutional and family-office co-investors in United Kingdom, Europe and North America.
        We believe in creating lasting value for our investors through prudent investment of their capital. We invest across various sectors from private equity, real estate to energy and yield superior benefits for our clients.
      </p>
    </div>

    <div className='sector-cards' id='sector-cards-id'>
            {data?.map((card)=>(
                <div className='sector-card-main' id='sector-card-main-id' onClick={()=>{ history.push(`/${card.title.split(' ').join('-')}`)}}>
                    <img src ={card.imgUrl} alt='img' className='sector-card-img' id='sector-card-img-id' />
                    <div className='sector-card-know-more'>
                    <p className="home-top-know-more"> Know more   <BsArrowRight/></p>
                    </div>
                    <div className='sector-card-detail'>
                        <h3 className='sector-card-id'>{card.id}</h3>
                        <h4 className='sector-card-title'>{card.title}</h4>
                    </div>
                    
                </div>
            ))

            }
        </div>

    {/* <div className='sector-cards' id='sector-cards-id'>
                <div className='sector-card-main' id='sector-card-main-id' onClick={()=>{ history.push(`/${data[0].title.split(' ').join('-')}`)}}>
                    <img src ={data[0].imgUrl} alt='img' className='sector-card-img' />
                    <div className='sector-card-know-more'>
                    <p className="home-top-know-more"> Know more   <BsArrowRight/></p>
                    </div>
                    <div className='sector-card-detail'>
                        <h3 className='sector-card-id'>{data[0].id}</h3>
                        <h4 className='sector-card-title'>{data[0].title}</h4>
                    </div>
                    
                </div>

                <div className='sector-card-main' id='sector-card-main-id' onClick={()=>{ history.push(`/${data[1].title.split(' ').join('-')}`)}}>
                    <img src ={data[1].imgUrl} alt='img' className='sector-card-img' />
                    <div className='sector-card-know-more'>
                    <p className="home-top-know-more"> Know more   <BsArrowRight/></p>
                    </div>
                    <div className='sector-card-detail'>
                        <h3 className='sector-card-id'>{data[1].id}</h3>
                        <h4 className='sector-card-title'>{data[1].title}</h4>
                    </div>
                </div>            
        </div>

        <div className='sector-cards' id='sector-cards-id'>
                <div className='sector-card-main' id='sector-card-main-id' onClick={()=>{ history.push(`/${data[2].title.split(' ').join('-')}`)}}>
                    <img src ={data[2].imgUrl} alt='img' className='sector-card-img' />
                    <div className='sector-card-know-more'>
                    <p className="home-top-know-more"> Know more   <BsArrowRight/></p>
                    </div>
                    <div className='sector-card-detail'>
                        <h3 className='sector-card-id'>{data[2].id}</h3>
                        <h4 className='sector-card-title'>{data[2].title}</h4>
                    </div>
                    
                </div>

                <div className='sector-card-main' id='sector-card-main-id' onClick={()=>{ history.push(`/${data[3].title.split(' ').join('-')}`)}}>
                    <img src ={data[3].imgUrl} alt='img' className='sector-card-img' />
                    <div className='sector-card-know-more'>
                    <p className="home-top-know-more"> Know more   <BsArrowRight/></p>
                    </div>
                    <div className='sector-card-detail'>
                        <h3 className='sector-card-id'>{data[3].id}</h3>
                        <h4 className='sector-card-title'>{data[3].title}</h4>
                    </div>
                </div>            
        </div> */}






    
    </div>










    // <div>
    //   <div className="firm__left">
    //     <h1>Our Firm</h1>
    //   </div>

    //   <Container className="firm__page">
    //     <h2 className="firm__heading">
    //       The Importance of Defining Your Investment Strategy
    //     </h2>
    //     <p className="firm__para">
    //       Eram Capital is a special situations investment company focusing on
    //       bringing unique investment opportunities to global institutional and
    //       family-office co-investors in United Kingdom, Europe and North
    //       America.
    //     </p>
    //     <p className="firm__para" style={{ "margin-bottom": "1.5rem" }}>
    //       We believe in creating lasting value for our investors through prudent
    //       investment of their capital. We invest across various sectors from
    //       private equity, real estate to energy and yield superior benefits for
    //       our clients.
    //     </p>
    //     <Row>
    //       <Col md={6} className="card">
    //         <img src={estate} className="firm__img" alt="Real Estate" />
    //         <div className="button_div">
    //           <Link to="/estate">
    //             <Button className="button">REAL ESTATE</Button>
    //           </Link>
    //         </div>
    //       </Col>
    //       <Col md={6} className="card">
    //         <img src={infrastructure} className="firm__img" alt="Real Estate" />
    //         <div className="button_div">
    //           <Link to="/infrastructure">
    //             <Button className="button">INFRASTRUCTURE</Button>
    //           </Link>
    //         </div>
    //       </Col>
    //       <Col md={6} className="card">
    //         <img src={agriculture} className="firm__img" alt="Agriculture" />
    //         <div className="button_div">
    //           <Link to="/agriculture">
    //             <Button className="button">AGRICULTURE</Button>
    //           </Link>
    //         </div>
    //       </Col>
    //       <Col md={6} className="card">
    //         <img src={energy} className="firm__img" alt="Energy" />
    //         <div className="button_div">
    //           <Link to="/energy">
    //             <Button className="button">ENERGY</Button>
    //           </Link>
    //         </div>
    //       </Col>
    //     </Row>

    //     <About />
    //     <Vision />
    //   </Container>
    // </div>
  );
}

export default Firm;

const data = [
  {
      imgUrl : realEstate,
      id : '01',
      title : 'Real Estate'
  },
  {
      imgUrl : infra,
      id : '02',
      title : 'Infrastructure'
  },
  {
      imgUrl : agri,
      id : '03',
      title : 'Agriculture'
  },
  {
      imgUrl : energy,
      id : '04',
      title : 'Energy'
  },

]