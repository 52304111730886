import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../strategy/Strategy.css"
import NavbaR from "../Navbar/NavbaR";
import {BsDot} from 'react-icons/bs';
import './Estate.css';

function Estate() {
  return (
    <div className="strategy-main">
      <div className="estate-top-div">
        <div><NavbaR/></div>
        <h1 className="strategy-label">Real Estate</h1>
      </div>

      <div className="strategy-evaluation-main">
        <h3 className="strategy-evaluation-title">Special Situations Investment Company</h3>
        <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
        <p className="evaluation-details"  id='evaluation-details-id'>Eram Capital Partners collaborates with medium to large size private and public companies operating in the residential and commercial real estate sectors in United Kingdom, Europe and North America, with a focus on Capital or large Commercial Cities. Eram Capital originates, develops opportunities and formulates partnerships with real estate developers and co-investors who wish to access and deploy strategic capital. Eram Capital and its investors focus on the following areas:
        </p>

      </div>

      <div className="evaluation-points-main">
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Residential, logistics, student housing, and commercial investments</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Pre- and post- construction development financing structures</p>
        </div>
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">High-income oriented investment opportunities</p>
        </div>
      </div>

        <p className="evaluation-details">Eram Capital Partners focuses on real estate projects and companies which are in key urban locations in the geographies we have unique access to and local expert teams on the ground. We and our co-investors believe in the global demographic and economic trends driving growth in the residential and commercial real estate sectors, largely governed by:</p>
  
        <div className="evaluation-points-main">
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Business globalization and the concentration in key locations</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Urbanization and general population growth</p>
        </div>
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Global growth in products and services logistics operations</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Increase in global capital allocation to the real estate asset class</p>
        </div>
      </div>

    
        <p className="evaluation-details">Eram Capital Partners has unique access to strategic and financial co-investors globally who have an abundance of capital resources and understand the global demographic trends driving real estate markets in key global cities in the UK, Europe, and the USA.
Eram and its co-investor partners have a long history of Real Estate sector experience in key geographies around the world, with a demonstrable track record.
Eram and its co-investor partners can provide development capital, growth capital for real estate projects and can facilitate strategic investing partnerships to attract pipeline opportunities in identified sub-sectors. to build platform investments.
</p>

    </div>
  )}

export default Estate;
