import React from "react";
import { useHistory } from "react-router-dom";
import "./Sectors.css";
import realEstate from "../../assets/images/Real-estate.png";
import infra from "../../assets/images/infra.png";
import agri from "../../assets/images/agriculture.jpg";
import energy from "../../assets/images/energy.png";
import { BsArrowRight } from "react-icons/bs";

export const Sectors = () => {
  const history = useHistory();

  return (
    <div className="sectors-main">
      <h1>Sectors We Work In</h1>
      <div className="title-placer">&nbsp;</div>

      <div className="sector-cards">
        {data?.map((card) => (
          <div
            className="sector-card-main"
            onClick={() => {
              history.push(`/${card.title.split(" ").join("-")}`);
            }}
          >
            <img src={card.imgUrl} alt="img" className="sector-card-img" />
            <div className="sector-card-know-more">
              <p className="home-top-know-more">
                {" "}
                Know more <BsArrowRight />
              </p>
            </div>
            <div className="sector-card-detail">
              <h3 className="sector-card-id">{card.id}</h3>
              <h4 className="sector-card-title">{card.title}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const data = [
  {
    imgUrl: realEstate,
    id: "01",
    title: "Real Estate",
  },
  {
    imgUrl: infra,
    id: "02",
    title: "Infrastructure",
  },
  {
    imgUrl: agri,
    id: "03",
    title: "Agriculture",
  },
  {
    imgUrl: energy,
    id: "04",
    title: "Energy",
  },
];
