import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Infrastructure.css";
import NavbaR from "../Navbar/NavbaR";
import {BsDot} from 'react-icons/bs';

function Infrastructure() {
  return (
    <div className="strategy-main">
    <div className="infra-top-div">
      <div><NavbaR/></div>
      <h1 className="strategy-label">Infrastructure</h1>
    </div>

    <div className="strategy-evaluation-main">
      <h3 className="strategy-evaluation-title">Special Situations Investment Company</h3>
      <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
      <p className="evaluation-details"  id='evaluation-details-id'>Eram Capital Partners collaborates with medium to large size private and public companies operating in industrial infrastructure in United Kingdom, Europe and North America. Eram Capital Partners develops opportunities and formulates partnerships with operator companies or assets who have expertise and growth prospects in the key industries. Eram Capital Partners and its investors focus on the following areas:
      </p>

    </div>

    <div className="evaluation-points-main">
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Airports</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Shipping</p>
      </div>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Bridges, dams, roads</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Water treatments</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Power Generation & Transmission</p>
      </div>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Communication networks</p>
      </div>
    </div>


        <p className="evaluation-details">The global economy is undergoing a significant transformation in the trend toward creating new infrastructure for increasing demand and the rapid adoption of manufacturing techniques. Eram Capital Partners and our co-investor partners believe in the profound demographic factors driving growth in the global Infrastructure sector. Some of the key factors are:</p>
    
        <div className="evaluation-points-main">
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">		Rapid population growth in the developing world putting pressure on existing infrastructure.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Increased global economic activity in both the developed and developing world demanding new infrastructure resources and efficiency</p>
        </div>
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Governmental policies facilitating support for infrastructure modernisation and expansion</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Urbanization and general population growth</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Global growth in products and services logistics operations.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Increase in global capital allocation to the infrastructure asset class.</p>
        </div>
      </div>


        <p className="evaluation-details">Eram Capital Partners has unique access to strategic and financial investors globally who have an abundance of capital resources and understand the global demographic trends driving the Infrastructure sectors. Eram Capital Partners and its co-investor partners have a long history of infrastructure sector experience in key geographies around the world. We can provide development capital, growth capital for companies and projects for foreign expansion. We can facilitate strategic and commercial investing partnerships globally to attract pipeline opportunities in identified sub-sectors – especially for platform investments that enable further investment opportunities.
    </p>

  </div>
  );
}

export default Infrastructure;
