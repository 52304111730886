import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import "./Contact.css";
import axios from "axios";
import NavbaR from "../Navbar/NavbaR";
import loc from "../../assets/images/loc-black.png";
import mail from "../../assets/images/mail-black.png";

function Contact() {
  const BACKEND_URL = "https://erambackend.herokuapp.com/api/contact/email";
  const EMAIL_To = "Admin@eramcapital.com";

  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleCompanyName = (e) => {
    setCompanyName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const data = {
      emailTo: EMAIL_To,
      name,
      companyName,
      email,
      message,
    };
    console.log(data);

    axios
      .post(BACKEND_URL, data)
      .then((res) => {
        alert(res?.data?.message);
      })
      .catch((error) => console.log(error));

    setName("");
    setCompanyName("");
    setEmail("");
    setMessage("");
    e.target.reset();
  };

  return (
    <div className="contact-main">
      <div className="contact-top-div">
        <div>
          <NavbaR />
        </div>
      </div>

      <div className="contact-form-div-main">
        <div className="contact-form-card">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3 className="contact-us-label">Contact Us</h3>
            <div
              className="title-placer"
              style={{
                width: "16rem",
                margin: "0",
                height: "2px",
                textAlign: "center",
              }}
            >
              &nbsp;
            </div>
          </div>
          <div className="contact-us-content">
            <div className="contact-form-main">
              <h6 className="contact-us-subtitle">Leave us a message</h6>
              <Form className="contact-form-div" onSubmit={sendEmail}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={handleName}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={handleEmail}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Your Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    type="email"
                    rows="4"
                    value={message}
                    onChange={handleMessage}
                  />
                </Form.Group>

                <Button type="submit" style={{ width: "100%" }}>
                  Send
                </Button>
              </Form>
            </div>

            <div className="contact-us-address">
              <div className="footer__add">
                <div className="footer__icon" style={{ color: "#ffffff" }}>
                  {/* <i
              className="fas fa-map-marked-alt"
              style={{ color: "#80c2fa" }}
            ></i> */}
                  <img
                    src={loc}
                    alt="location"
                    className="footer-location-icon-img"
                  />
                </div>
                <div style={{ textAlign: "left" }}>
                  International house,
                  <br /> 6 Molton Street,
                  <br /> London, England W1K5QF
                </div>
              </div>
              <div className="footer__add">
                <div className="footer__icon">
                  {/* <i className="fas fa-envelope" style={{ color: "#80c2fa" }}></i> */}
                  <img
                    src={mail}
                    alt="location"
                    className="footer-location-icon-img"
                  />
                </div>
                <div>info@erampartners.com</div>
              </div>

              <div className="contact__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.998976140451!2d-0.14942368467161893!3d51.5132347796361!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876052c9bfbbad7%3A0x17eaeda9b0a663b0!2s6%20S%20Molton%20St%2C%20London%20W1K%205QF%2C%20UK!5e0!3m2!1sen!2sin!4v1645609392962!5m2!1sen!2sin"
                  width="100%"
                  height="250"
                  style={{ border: "0" }}
                  title="map"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div className="contact-main">
    //   <div className="contact-top-div">
    //     <div><NavbaR/></div>

    //     <div className="contact-form-div-main">
    //     <div className="contact-form-card">
    //       <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
    //       <h3 className="contact-us-label">Contact Us</h3>
    //       <div className="title-placer" style={{width:'16rem',margin:'0', height:'2px',textAlign:'center'}}>&nbsp;</div>
    //       </div>
    //       <div className="contact-us-content">
    //         <div className='contact-form-main'>
    //         <h6 className="contact-us-subtitle">Leave us a message</h6>
    //         <Form className="contact-form-div">
    //           <Form.Group className="mb-3" controlId="formBasicEmail">
    //             <Form.Label>Name</Form.Label>
    //             <Form.Control type="email" placeholder="First_Name Last_Name" />
    //           </Form.Group>

    //           <Form.Group className="mb-3" controlId="formBasicEmail">
    //           <Form.Label>Email address</Form.Label>
    //             <Form.Control type="email" placeholder="Enter email" />
    //           </Form.Group>

    //           <Form.Group className="mb-3" controlId="formBasicEmail">
    //           <Form.Label>Your Message</Form.Label>
    //             <Form.Control as="textarea" rows="3" type="email" placeholder="Enter Message"  rows='4' />
    //           </Form.Group>

    //           <Button
    //             type="submit" style={{width:'100%'}}
    //           >
    //             Send
    //           </Button>
    //         </Form>

    //         </div>

    //         <div className="contact-us-address">
    //         <div className="footer__add">
    //         <div className="footer__icon" style={{ color: '#ffffff' }}>
    //           {/* <i
    //             className="fas fa-map-marked-alt"
    //             style={{ color: "#80c2fa" }}
    //           ></i> */}
    //         <img src={loc} alt='location' className='footer-location-icon-img' />

    //         </div>
    //         <div style={{textAlign:'left'}}>
    //         International house,
    //           <br /> 6 Molton Street,
    //           <br /> London, England W1K5QF
    //         </div>
    //       </div>
    //       <div className="footer__add">
    //         <div className="footer__icon">
    //           {/* <i className="fas fa-envelope" style={{ color: "#80c2fa" }}></i> */}
    //           <img src={mail} alt='location' className='footer-location-icon-img' />
    //         </div>
    //         <div>info@erampartners.com</div>
    //       </div>

    //       <div className="contact__map">
    //         <iframe
    //           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.234954857578!2d-0.09117408436340421!3d51.52725027963843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ca643778c95%3A0x3cb31390794fe0c9!2s160%20City%20Rd%2C%20London%20EC1V%202NX!5e0!3m2!1sen!2suk!4v1636349441398!5m2!1sen!2suk"
    //           width="100%"
    //           height="250"
    //           style={{ border: "0" }}
    //           title="map"
    //           allowfullscreen=""
    //           loading="lazy"
    //         ></iframe>
    //         </div>
    //         </div>

    //       </div>
    //     </div>
    //   </div>
    // </div>
    // </div>
    // <div>
    //   <div className="contact__top">
    //     <h1>Contact</h1>
    //   </div>
    // <div className="contact__map">
    //   <iframe
    //     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.234954857578!2d-0.09117408436340421!3d51.52725027963843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ca643778c95%3A0x3cb31390794fe0c9!2s160%20City%20Rd%2C%20London%20EC1V%202NX!5e0!3m2!1sen!2suk!4v1636349441398!5m2!1sen!2suk"
    //     width="100%"
    //     height="330"
    //     style={{ border: "0" }}
    //     title="map"
    //     allowfullscreen=""
    //     loading="lazy"
    //   ></iframe>
    // </div>

    //   <Container className="contact__bottom">
    //     <Row>
    //       <Col md={3} className="contact__bottom1">
    //         <p>Our Location</p>
    //         <hr />
    //         <p>
    //           Kemp House,
    //           <br /> 160 City Road, London,
    //           <br /> United Kingdom, EC1V 2NX
    //         </p>
    //         <br />
    //         <p>Email</p>
    //         <hr />
    //         <p>info@eramcapital.com</p>
    //       </Col>

    //       <Col md={9} className="contact__bottom2">
    // <form onSubmit={sendEmail}>
    //   <input
    //     type="text"
    //     placeholder="Name"
    //     name="name"
    //     className="contact__input"
    //     onChange={handleName}
    //     value={name}
    //     required
    //   ></input>
    //   <input
    //     type="text"
    //     placeholder="Company Name"
    //     name="company_name"
    //     className="contact__input"
    //     onChange={handleCompanyName}
    //     value={companyName}
    //     required
    //   ></input>
    //   <input
    //     type="text"
    //     placeholder="Email"
    //     name="email"
    //     className="contact__input"
    //     onChange={handleEmail}
    //     value={email}
    //     required
    //   ></input>
    //   <input
    //     type="text"
    //     placeholder="Message"
    //     name="message"
    //     className="contact__input"
    //     onChange={handleMessage}
    //     value={message}
    //     required
    //   ></input>

    //   <Button
    //     variant="light"
    //     size="lg"
    //     type="submit"
    //     className="contact__button"
    //   >
    //     SUBMIT
    //   </Button>
    // </form>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  );
}

export default Contact;
