import React from "react";
import ERAM from "../../assets/images/ECP-croped.jpg";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <Container className="footer__main">
      <Row className="footer__sub">
        <Col md={5} className="footer__first">
          <img src={ERAM} alt="logo" className="footer__image"></img>

          <div className="footer__add">
            <div className="footer__icon">
              <i
                className="fas fa-map-marked-alt"
                style={{ color: "#80c2fa" }}
              ></i>
            </div>
            <div>
              Kemp House,
              <br /> 160 City Road, London,
              <br /> United Kingdom, EC1V 2NX
            </div>
          </div>
          <div className="footer__add">
            <div className="footer__icon">
              <i className="fas fa-envelope" style={{ color: "#80c2fa" }}></i>
            </div>
            <div>info@eramcapital.com</div>
          </div>
          <div className="footer__copy">
            Eram Capital Advisors © 2021. All rights reserved.
          </div>
        </Col>

        <Col md={7} className="footer__second">
          <h3>About Us</h3>
          <br />
          <p>
            Eram Capital is a special situations investment company focusing on
            bringing unique investment opportunities to global institutional and
            family-office co-investors in United Kingdom, Europe and North
            America. We believe in creating lasting value for our investors
            through prudent investment of their capital. We invest across
            various sectors from private equity, real estate to energy and yield
            superior benefits for our clients.
          </p>
          <Link to="/privacy-policy">
            <Button className="footer__pol">Privacy Policy</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
