import React from "react";
import NavbaR from "../Navbar/NavbaR";
import {BsDot} from 'react-icons/bs';
import './Strategy.css';

function Strategy() {
  return (
    <div className="strategy-main">
      <div className="strategy-top-div">
        <div><NavbaR/></div>
        <h1 className="strategy-label">Strategy</h1>
      </div>

      <div className="strategy-evaluation-main">
        <h3 className="strategy-evaluation-title">Evaluation Criteria</h3>
        <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
        <p className="evaluation-details" id='evaluation-details-id'>Eram Capital Partners and its investor partners focus on companies and projects, either directly or via portfolio investments through sponsors, which can deliver unique return performance with acceptable and manageable levels of risk.
        </p>
      </div>

      
        <p className="evaluation-details" >Eram Capital Partners focuses on special situations opportunities defined as those opportunities offering unique investment return potential with atypical risk characteristics. The scope of our screening process is not limited to a narrow definition and may include restructuring opportunities, growth capital situations, complex structure investments, or early stage development projects.
      </p>
      

        <p className="evaluation-details">Eram Capital Partners works closely with potential companies and identifies investment opportunities that are synergistic to both the companies and our investor partners. We base this determination on a number of fundamental bottoms-up factors which complement our assessment of the opportunity alongside the strategic factors. Our key evaluation criteria of the company, project, or sponsored portfolio investment are:
      </p>
  


      <div className="evaluation-points-main">
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Management performance, integrity and governance.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Past and projected financial performance.</p>
        </div>
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Supporting macro economic growth factors.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Risk / return profile and transparency of the investment structure.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Liquidity of the investment with emphasis on exit strategies.</p>
        </div>
      </div>

        <p className="evaluation-details">Eram Capital Partners works closely with potential companies and identifies investment opportunities that are synergistic to both the companies and our investor partners. We base this determination on a number of fundamental bottoms-up factors which complement our assessment of the opportunity alongside the strategic factors. Our key evaluation criteria of the company, project, or sponsored portfolio investment are:
      </p>

      <div className="evaluation-points-main">
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Business Strategy:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Clarity and longevity of strategy.</p>
      </div>
      
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Feasibility.</p>
      </div>
      
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Innovation.</p>
      </div>

      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Complementary fit with our other investments:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Opportunities for the domestic market.</p>
      </div>
      
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Opportunities for the domestic market.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Willingness to develop partnerships</p>
      </div>

      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Growth Capital Expansion:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Marginal impact of capital investment on growth.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Life cycle of business plan.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Magnitude of capital requirement.</p>
      </div>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Strategic Sector or Geography</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Growth cycle of sector or region.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Demographic drivers.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Sustainability</p>
      </div>
      </div>

    </div>

        <p className="evaluation-details">Eram Capital Partners and its investor partners focus on companies and projects, either directly or via portfolio investments through sponsors, that exhibit strong bias toward sustainable growth and feasibility of success over the medium term.
      </p>

    </div>
      );
    }
    
    export default Strategy;