import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar/NavbaR";
import Footer from "./components/Footer/Footer";
import Firm from "./components/firm/Firm";
import Investment from "./components/services/Investment";
import Principal from "./components/services/Principal";
import Strategy from "./components/strategy/Strategy";
import Contact from "./components/contact/Contact";
import Home from "./components/home/Home";
import Policy from "./components/policy/Policy";
import ScrollToTop from "./components/ScrollToTop";
import Cookie from "./components/Cookie/Cookie";
import Home1 from "./components/home/Home";
import { AboutUs } from "./components/About/AboutUs";
import Agriculture from "./components/Agriculture/Agriculture";
import Energy1 from "./components/Energy/Energy";
import Estate from "./components/Estate/Estate";
import Energy from "./components/Energy/Energy";
import Infrastructure from "./components/Infrastructure/Infrastructure";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        
        <Switch>
          <Route exact path="/" component={Home1}></Route>
          <Route exact path="/firm" component={Firm}></Route>
          <Route exact path="/investment" component={Investment}></Route>
          <Route exact path="/principal" component={Principal}></Route>
          <Route exact path="/agriculture" component={Agriculture}></Route>
          <Route exact path="/energy" component={Energy}></Route>
          <Route exact path="/Real-Estate" component={Estate}></Route>
          <Route exact path="/Infrastructure" component={Infrastructure}></Route>
          <Route exact path="/strategy" component={Strategy}></Route>
          <Route exact path="/contact" component={Contact}></Route>
          <Route exact path="/privacy-policy" component={Policy}></Route>
        </Switch>
        <AboutUs />

        <Cookie />
      </BrowserRouter>
    </div>
  );
}

export default App;
