import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import principal from "../../assets/images/principal.jpg";
import "./Principal.css";
import NavbaR from "../Navbar/NavbaR";
import {BsDot} from 'react-icons/bs'

function Principal() {
  return (
    <div className="strategy-main">
    <div className="principal-top-div">
      <div><NavbaR/></div>
      <h1 className="strategy-label">Principal Investments</h1>
    </div>
    <div className="strategy-evaluation-main">
      <h3 className="strategy-evaluation-title">Investment Activities</h3>
      <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
      <p className="evaluation-details">Eram Capital deploys its own partner capital toward unique special situations investment opportunities to co-invest alongside our other investment partners or to serve as an anchor investor. Eram specializes in complex, illiquid, high return and long-dated investment structures and utilizes its expertise to efficiently invest in such opportunities. Eram can deploy proprietary capital toward working capital and investment capital requirements at the company, project or asset level.
      </p>

    </div>

    <div className="evaluation-points-main">
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Investing proprietary capital alongside investor clients in deals originated by Eram.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Facilitating transactions in the form of working capital for deal due diligence & structuring or investment capital in the underlying asset or company.</p>
      </div>
    </div>

  </div>

  );
}

export default Principal;
